import { signInWithEmailAndPassword } from 'firebase/auth'
import React, { useState } from 'react'
import Button from '../components/basics/button'
import LabeledInput from '../components/basics/labeled-input'
import { auth } from '../helpers/firebase'

function Login() {
    const [loginInfo, setLoginInfo] = useState({
        email: "",
        password: ""
    })
    const [loading, setLoading] = useState(false)


    const canLogin = loginInfo?.email !=="" && loginInfo?.password !==""
    return (
        <>
            <div className="flex flex-col items-center py-7 w-full">
                <div className="px-5 mb-8">
                    <h2 className="text-center font-quincy font-semibold text-5xl md:text-5xl lg:text-6xl tracking-wide">
                        Manage your inventory and orders.
                    </h2>
                    <p className={`text-center lg:text-xl text-lg font-inter mt-2`}>
                        Log in using your email and password below.
                    </p>
                </div>
                <div className='lg:w-1/3 w-full px-10 lg:px-0 flex flex-col items-center mt-2'>
                    <LabeledInput
                        label="Email"
                        placeholder="Enter Your Email"
                        className="w-full rounded border-dark border px-3 py-1"
                        onChange={(e) => setLoginInfo({...loginInfo, email: e.target.value})}
                        value={loginInfo.email}
                    />
                    <LabeledInput
                        label="Password"
                        placeholder="Enter Your Password"
                        className="w-full rounded border-dark border px-3 py-1"
                        onChange={(e) => setLoginInfo({...loginInfo, password: e.target.value})}
                        value={loginInfo.password}
                    />
                    <Button 
                        className={`${!canLogin ? 'border border-[#9B9FA3] bg-white text-[#9B9FA3]' : 'bg-dark text-white'} font-medium w-full h-16 mt-7 `} 
                        label="Login"
                        onClick={async () => {
                            setLoading(true)
                            await signInWithEmailAndPassword(auth, loginInfo.email, loginInfo.password)
                            .then((userCredential) => {
                            // Signed in 
                                const user = userCredential.user;
                                console.log(user)
                                setLoading(false)
                            })
                            .catch((error) => {
                                const errorMessage = error.status;
                                alert(errorMessage)
                                setLoading(false)
                            });
                        }}
                        loading={loading}
                    />
                </div>
                {/* <div className='flex-col flex lg:flex-row items-center justify-between gap-5 lg:w-1/3 mt-6'>
                    <Link href="/rooms">
                        <p className='text-[#0069CC] cursor-pointer'>Book a room</p>
                    </Link>
                    <Link href="/portal/login/forgot-password">
                        <p className='text-[#0069CC] cursor-pointer'>Forgot password?</p>
                    </Link>
                </div> */}
            </div>
        </>
    )
}

export default Login