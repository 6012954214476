import moment from "moment";
import { createContext, useContext, useState, useEffect } from "react";

export const AppContext = createContext();

export function AppWrapper({ children }) {
  const [checkout, setCheckout] = useState({});

  const [rooms, setRooms] = useState([]);
  const [refresh, setrefresh] = useState(0);
  const [admins, setAdmins] = useState([]);
  const [availableRooms, setAvailableRooms] = useState([]);
  const [loggedIn, setLoggedIn] = useState(undefined);
  const [role, setRole] = useState(undefined);
  const [menu, setmenu] = useState([]);
  const [barMenu, setBarMenu] = useState([]);
  const [orders, setorders] = useState([]);
  const [activeBookings, setActiveBookings] = useState([]);
  const [allBookings, setAllBookings] = useState([]);
  const [reports, setReports] = useState([]);
  const [content, setContent] = useState([]);
  const [editImages, setEditImages] = useState([]);
  const [menuMode, setMenuMode] = useState("");

  const [editable, setEditable] = useState({
    name: "",
    price: 0,
    category: "",
    id: "",
  });

  const [bookable, setBookable] = useState({
    id: "",
    roomId: "",
    price: 0,
    roomtype: "",
    roomnumber: "",
  });

  const [adminable, setAdminable] = useState({
    firstname: "",
    lastname: "",
    uid: "",
  });

  const [openSideView, setOpenSideView] = useState(false);

  const [mode, setmode] = useState(undefined);
  const [sideMode, setSideMode] = useState("");

  const [cart, setcart] = useState([]);

  const sharedState = {
    checkout,
    setCheckout,
    rooms,
    availableRooms,
    setAvailableRooms,
    loggedIn,
    setLoggedIn,
    cart,
    setcart,
    openSideView,
    setOpenSideView,
    mode,
    setmode,
    menu,
    barMenu,
    orders,
    activeBookings,
    reports,
    admins,
    sideMode,
    setSideMode,
    editable,
    setEditable,
    refresh,
    setrefresh,
    bookable,
    setBookable,
    adminable,
    setAdminable,
    role,
    setRole,
    allBookings,
    setAllBookings,
    content,
    setContent,
    editImages,
    setEditImages,
    menuMode,
    setMenuMode,
  };

  const fetchRoomsData = async () => {
    return await fetch("https://api.ibomwaterfallsuites.com/rooms")
      .then((response) => response.json())
      .then((data) => setRooms(data));
  };

  const fetchMenuData = async () => {
    return await fetch("https://api.ibomwaterfallsuites.com/get-menu")
      .then((response) => response.json())
      .then((data) => setmenu(data));
  };

  const fetchBarMenuData = async () => {
    return await fetch("https://api.ibomwaterfallsuites.com/get-bar-menu")
      .then((response) => response.json())
      .then((data) => setBarMenu(data));
  };

  const fetchOrdersData = async () => {
    return await fetch(`https://api.ibomwaterfallsuites.com/orders/`)
      .then((response) => response.json())
      .then((data) => {
        setorders(data);
      });
  };

  const fetchContentData = async () => {
    return await fetch("https://api.ibomwaterfallsuites.com/get-content/")
      .then((response) => response.json())
      .then((data) => {
        setContent(data);
      });
  };

  const fetchActiveBookingsData = async () => {
    return await fetch(`https://api.ibomwaterfallsuites.com/bookings/`)
      .then((response) => response.json())
      .then((data) => {
        setAllBookings(data);
        let today = moment();

        let todaybookings = data.filter(
          (d) =>
            today >= moment(d.data.dateIn).hour(12).minute(0).second(0) &&
            today <= moment(d.data.dateOut).hour(12).minute(0).second(0)
        );
        setActiveBookings(todaybookings);
      });
  };

  const fetchReportsData = async () => {
    return await fetch(`https://api.ibomwaterfallsuites.com/reports/`)
      .then((response) => response.json())
      .then((data) => {
        setReports(data);
      });
  };

  const fetchAdminsData = async () => {
    return await fetch(`https://api.ibomwaterfallsuites.com/admins/`)
      .then((response) => response.json())
      .then((data) => {
        const currentAdmin = data?.find((a) => a?.id === loggedIn?.uid);
        if (currentAdmin !== undefined) {
          setRole(currentAdmin.data.role);
          console.log(currentAdmin?.data?.role);
          switch (currentAdmin?.data?.role) {
            case "super":
              setmode("orders");
              break;
            case "manager":
              setmode("orders");
              break;
            case "reception":
              setmode("manage-rooms");
              break;
            case "restaurant":
              setmode("orders");
              break;
            case "pool-restaurant":
              setmode("orders");
              break;
            case "bar":
              setmode("orders");
              break;
            default:
              setmode("");
          }
        }
        setAdmins(data);
      });
  };

  useEffect(() => {
    fetchRoomsData();
    fetchMenuData();
    fetchBarMenuData();
    fetchOrdersData();
    fetchActiveBookingsData();
    fetchReportsData();
    fetchAdminsData();
    fetchContentData();
  }, [refresh, loggedIn]);

  // console.log(sharedState)

  return (
    <AppContext.Provider value={sharedState}>{children}</AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
