import { useAppContext } from '../../helpers/store'
import EditContent from './edit-content'

const ContentSection = ({ className, pageContent }) => {
    let subsections = pageContent?.data ? Object.keys(pageContent?.data) : []
    const context = useAppContext()
    

    return (
        <div className={className}>
            {subsections.map((section, i) => (
                <div key={section}>
                    <h2 className='text-lg font-semibold p-4'>{section}</h2>
                    <div className='grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-8'>
                        {pageContent?.data?.[section]?.map((imageUrl, index) => {
                            return (
                                <EditContent
                                    key={`${imageUrl}-${index}`}
                                    url={imageUrl}
                                    replaceImage={() => {
                                        let editImages = [...context?.editImages]
                                        if(editImages.findIndex(image => image.section === section && image.index === index) === -1) {
                                            editImages.push({section, index})
                                            context?.setEditImages(editImages)
                                        }
                                        else {
                                            editImages.splice(editImages.findIndex(image => image.section === section && image.index === index), 1)
                                            context?.setEditImages(editImages)
                                        }
                                    }}
                                />
                            )
                        })}
                    </div>
                </div>
            ))}

        </div>
    )
}

export default ContentSection
