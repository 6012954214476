import { useState } from 'react'

function EditContent({url, replaceImage}) {
    const [selected, setSelected] = useState(false)
    return (
        <div>
            <div 
                className={`rounded-md overflow-hidden hover:opacity-50 cursor-pointer relative w-32 h-28 lg:w-40 lg:h-32 ${selected && 'border-2 border-black'}`}
                onClick={() => {
                    setSelected(!selected)
                    replaceImage()
                }}
            >
                <img src={url} className="w-full h-full" alt="editable" />
            </div>
        </div>
    )
}

export default EditContent