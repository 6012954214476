import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '../basics/button';
import { useState } from 'react';

function MenuTable({cart, editItem, menu, setcart}) {

    const alreadyInCart = (name) => {
        let cartItem = cart?.find((item) => item.name === name)

        return cartItem !== undefined ? true : false
    }

    const [filtermenu, setfiltermenu] = useState(menu)

    const categories = menu?.map((item) => item?.data?.category)
    const distinctCategories = [...new Set(categories)]
    distinctCategories.unshift("ALL")
    return (
        <TableContainer>
            <div className='my-10'>
                <select 
                    className='outline-none' 
                    onChange={(e) => {
                        setfiltermenu(e.target.value === 'ALL' ? menu : menu?.filter((item) => item.data?.category === e.target.value))
                    }
                }>
                    {distinctCategories?.map((category) => (
                        <option key={category} value={category}>
                            {category.toUpperCase()}
                        </option>
                    ))}
                </select>
            </div>
            <Table aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell><b>Name</b></TableCell>
                    <TableCell><b>Price</b></TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {filtermenu?.map((item, index) => (
                        <TableRow
                        key={index}
                        sx={{ 
                            '&:last-child td, &:last-child th': { border: 0 }, 
                            '&:nth-of-type(odd)': {
                                backgroundColor: '#F0F1F2',
                            },
                            ":hover": {
                                opacity: 0.8
                            },
                            textTransform: 'capitalize'
                        }}
                        >
                        <TableCell></TableCell>
                        <TableCell component="th" scope="row">
                            {item?.data?.name}
                        </TableCell>
                        <TableCell>{item?.data?.price}</TableCell>
                        <TableCell align="right">
                            <Button 
                                label={alreadyInCart(item?.data?.name) ? "Remove" : "Add to Cart"}
                                className={`bg-white border border-[#E1E4E8] 
                                ${alreadyInCart(item?.data?.name) ? "text-[#FA2828]" : "text-dark"} `}
                                onClick={() => {
                                    if(alreadyInCart(item?.data?.name)) {
                                        let newCart = [...cart]
                                        let deleteIndex = newCart.findIndex((c) => c.name === item?.data?.name)
                                        newCart.splice(deleteIndex, 1)
                                        setcart(newCart)
                                    } else {
                                        setcart([...cart, {name: item?.data?.name, price: item?.data?.price, type: item?.data?.type, quantity: 1}])
                                    }
                                }}
                            />
                        </TableCell>
                        <TableCell align="right">
                            <Button 
                                label="Edit"
                                className="bg-white text-dark w-40 border border-[#E1E4E8]"
                                onClick={() => editItem(item?.id, item?.data?.name, item?.data?.price, item?.data?.category)}
                            />
                        </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default MenuTable