import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { auth } from './helpers/firebase';
import { useAppContext } from './helpers/store';
import Login from './login';
import Portal from './portal';

function App() {
    const [user, setuser] = useState(undefined)
    const context = useAppContext()

    useEffect(() => {
        onAuthStateChanged(auth, (u) => {
            if(u) {
                setuser(u)
                context?.setLoggedIn(u)
                console.log(u)
            } else {
                setuser(undefined)
            }
        })
    }, [])

    const portalInfo = () => {
        switch (context?.mode) {
            case 'orders':
                return {
                    headerText: `Manage your orders, ${user?.displayName}`
                }
            case 'menu':
                return {
                    headerText: `Manage the menu, ${user?.displayName}`
                }
            case 'bar-menu':
                return {
                    headerText: `Manage the Bar, ${user?.displayName}`
                }
            case 'manage-rooms':
                return {
                    headerText: `Manage Rooms`
                }
            case 'reports':
                return {
                    headerText: `Reports and Complaints`
                }
            case 'administrators':
                return {
                    headerText: `Manage Administrators`
                }    
            case 'bookings':
                return {
                    headerText: `See All Bookings`
                } 
            case 'content':
                return {
                    headerText: `Manage Content`
                }    
            case 'logout':
                return {
                    headerText: ``
                }
            default:
                return {
                    headerText: ``
                }
        }
    }
    return (
        <div>
            {user !== undefined ? (
                <Portal headerText={portalInfo().headerText} displayName={user?.displayName} uid={user?.uid} />
            ) : (
                <Login />
            )}
        </div>
    )
}

export default App