import React from 'react'

function PortalHeader({headerText}) {
  return (
    <div className='w-full p-6 bg-white border-l border-l-[#F8F8FA]'>
        <p className='font-quincy text-3xl lg:mt-12'>{headerText}</p>
    </div>
  )
}

export default PortalHeader