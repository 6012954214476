import { Dialog, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import Button from '../../components/basics/button'
import ReportsTable from '../../components/widgets/reports-table'


function Report({reports}) {
    const [openReport, setOpenReport] = useState(false)
    const [report, setReport] = useState({
        displayName: '',
        message: ''
    })
    return (
        <div className="w-full p-6">
            <ReportsTable 
                reports={reports}
                setReport={setReport}
                setOpenReport={setOpenReport}
            />
            <Dialog onClose={() => setOpenReport(false)} open={openReport}>
                <DialogTitle>{report?.displayName}'s Report</DialogTitle>
                <div className='w-full border-y border-dark flex justify-center py-2 px-6'>
                    <p>{report?.message}</p>
                </div>
                <div className='flex w-full items-center justify-end px-6 py-3'>
                    <Button 
                        label="Close"
                        className="bg-white text-dark"
                        onClick={() => setOpenReport(false)}
                    />
                </div>
            </Dialog>
        </div>
    )
}

export default Report