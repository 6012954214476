import { MagnifyingGlassIcon, PlusIcon } from "@heroicons/react/24/solid"
import Button from "../../components/basics/button"
import MenuTable from "../../components/widgets/menu-table"
import { useAppContext } from "../../helpers/store"

function Menu() {
    const context = useAppContext()
    return (
        <div className="w-full p-6">
            <div className="flex items-center w-full justify-between">
                <div className="flex-col lg:flex-row flex items-center gap-6">
                    <div className="lg:w-1/2 w-1/2 py-3 pl-2 bg-[#F8F8FA] rounded-md flex gap-2 items-center mb-5">
                        <MagnifyingGlassIcon className="w-6 h-6 text-dark" />
                        <input placeholder="Search items" className="bg-transparent outline-none" />
                    </div>
                    <div 
                        className="py-3 px-3 cursor-pointer bg-[#1B1F23] text-white rounded-md flex gap-2 items-center mb-5"
                        onClick={() => {
                            context?.setOpenSideView(true)
                            context?.setSideMode('add-item')
                            context?.setMenuMode('restaurant')
                        }}
                    >
                        <PlusIcon className="w-6 h-6 text-white" />
                        <p className="text-white">Add to Menu</p>
                    </div>
                </div>
                <Button 
                    label="View Cart"
                    className="border border-[#E1E4E8] bg-white text-dark"
                    onClick={() => {
                        context?.setOpenSideView(true)
                        context?.setSideMode('cart')
                    }}
                    disabled={context?.cart?.length === 0}
                />
            </div>
            <MenuTable 
                menu={context?.menu}
                cart={context?.cart}
                setcart={context?.setcart}
                editItem={(id, name, price, category) => {
                    context?.setOpenSideView(true)
                    context?.setSideMode('edit-item')
                    context?.setMenuMode("restaurant")
                    context?.setEditable({
                        name, price, id, category
                    })
                }}
            />
        </div>
    )
}

export default Menu