import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '../basics/button';

function AdminTable({admins, editAdmin}) {
  return (
    <TableContainer>
        <Table aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell></TableCell>
                <TableCell><b>Username</b></TableCell>
                <TableCell><b>Email</b></TableCell>
                <TableCell><b>Role</b></TableCell>
                <TableCell><b>Status</b></TableCell>
                <TableCell align="right"></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {admins?.map((admin, index) => (
                <TableRow
                    key={index}
                    sx={{ 
                        '&:last-child td, &:last-child th': { border: 0 }, 
                        '&:nth-of-type(odd)': {
                            backgroundColor: '#F0F1F2',
                        },
                        ":hover": {
                            opacity: 0.8
                        },
                    }}
                >
                    <TableCell></TableCell>
                    <TableCell component="th" scope="row" sx={{textTransform: 'capitalize'}}>
                        {admin?.data?.displayName}
                    </TableCell>
                    <TableCell>{admin?.data?.email}</TableCell>
                    <TableCell sx={{textTransform: 'capitalize'}}>{admin?.data?.role}</TableCell>
                    <TableCell>{admin?.data?.status}</TableCell>
                    <TableCell align="right">
                        <Button 
                            label="Edit"
                            className="bg-white text-dark w-20 border border-[#E1E4E8]"
                            onClick={() => editAdmin(admin?.id, admin?.data?.displayName)}
                        />
                    </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
    </TableContainer>
  )
}

export default AdminTable