import { ChevronRightIcon } from '@heroicons/react/24/solid'
import React from 'react'

function SidebarOption({label, image, onClick, logout, active}) {
    return (
        <div className={`w-full cursor-pointer flex ${logout && 'text-[#FA2828] hover:bg-[#FFE0E0]'} justify-between items-center px-5 py-3 hover:bg-[#F0F1F2] ${active && "bg-[#F0F1F2]"} rounded-md`} onClick={onClick}>
            <div className='flex items-center gap-6'>
                <img src={image} className="h-5 w-5" alt="iconic" />
                <p>{label}</p>
            </div>
            {!logout && <ChevronRightIcon className='h-5 w-4' />}
        </div>
    )
}

export default SidebarOption