import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "../basics/button";
import { useAppContext } from "../../helpers/store";
import jsPDF from "jspdf";
import { useState } from "react";

function BookingsTable({ bookings, setBookings }) {
  const context = useAppContext();
  const [filter, setFilter] = useState("");

  const [reportText, setReportText] = useState("All Time");

  const uniqueYears = [
    ...new Set(context?.allBookings?.map((b) => b.data.dateIn.split("-")[0])),
  ];
  const uniqueMonths = [
    ...new Set(
      context?.allBookings?.map(
        (b) => `${b.data.dateIn.split("-")[0]} - ${b.data.dateIn.split("-")[1]}`
      )
    ),
  ];
  const uniqueDays = [
    ...new Set(
      context?.allBookings?.map(
        (b) =>
          `${b.data.dateIn.split("-")[0]} - ${b.data.dateIn.split("-")[1]} - ${
            b.data.dateIn.split("-")[2]
          }`
      )
    ),
  ];
  const uniqueMails = [
    ...new Set(context?.allBookings?.map((b) => b.data.email)),
  ];
  const rooms = context?.rooms?.map((r) => r.data.roomnumber);

  const getRoomPrice = (roomId) => {
    switch (roomId.substring(0, 2)) {
      case "DX":
        return 35000;
      case "EX":
        return 45000;
      case "EZ":
        return 45000;
      case "VP":
        return 55000;
      case "PR":
        return 55000;
      case "PH":
        return 200000;
      case "BR":
        return 250000;
      case "HA":
        return 950000;
      default:
        return 0;
    }
  };

  const tableToPDF = () => {
    // var csv_data = []

    // var rows = document.getElementsByTagName('tr')
    // for(var i = 0; i < rows.length; i++) {
    //     //get each column data
    //     var cols = rows[i].querySelectorAll('td,th')

    //     var csvrow = []
    //     for (var j = 0; j < cols.length; j++) {
    //         csvrow.push(cols[j].innerHTML)
    //     }
    //     //combine each column value with comma
    //     csv_data.push(csvrow.join(","))
    // }
    // //combine each row data with new line
    // csv_data = csv_data.join("\n")

    // downloadCSVFile(csv_data)
    var doc = new jsPDF("l", "pt", "a4");
    doc.addImage("/images/PDF_header.png", "PNG", 50, 10, 500, 80);
    doc.text(
      `Ibom Waterfall Suites Bookings Report for ${reportText}`,
      120,
      120
    );
    doc.html(document.querySelector("#bookings-table"), {
      callback: function (doc) {
        doc.save("bookings.pdf");
      },
      margin: [10, 20, 50, 20],
      x: 10,
      y: 130,
      width: 800,
      windowWidth: 800,
    });
    let pagesize = doc.internal.pageSize;
    let pageheight = pagesize.height ? pagesize.height : pagesize.getHeight();
    console.log(pageheight);
    doc.addImage("/images/PDF_footer.png", "PNG", 50, pageheight - 80, 500, 80);
  };

  // const downloadCSVFile = (csv_data) => {
  //     //create csv file object and feed our csv data to it
  //     var CSVFile = new Blob([csv_data], { type: "text/csv" })

  //     //create a temporary link
  //     var temp_link = document.createElement('a')

  //     //download
  //     temp_link.download = "Bookings.csv"
  //     var url = window.URL.createObjectURL(CSVFile)
  //     temp_link.href = url

  //     //dont display the link
  //     temp_link.style.display = "none"
  //     document.body.appendChild(temp_link)

  //     //automatically click link
  //     temp_link.click()
  //     document.body.removeChild(temp_link)

  // }

  const printReceipt = (
    name,
    email,
    roomnumber,
    price,
    discountedPrice,
    dateIn,
    dateOut,
    numDays
  ) => {
    // Create a printable version of the order
    const printableOrder = `
            <div>
            <h1>Ibom Waterfall Resorts & Suites</h1>
            <h2>Room Booking Receipt</h2>
            <p>Room ${roomnumber} for ${name} (${email})</p>
            <p>From ${dateIn} till ${dateOut} (${numDays})</p>
            <p>Original Room Price: ${price}</p>
            <p>Discounted Room Price: ${discountedPrice}</p>
            <button class="print-button">Print Receipt</button>
            </div>
        `;

    // Create a new window with the printable version of the order
    const printWindow = window.open("", "PrintWindow", "height=500,width=500");
    printWindow.document.write(printableOrder);

    // Add a button to the printable version of the order that the user can click to trigger the print dialog
    // Wait for the window to finish loading printWindow.onload = () => {
    // Add a print stylesheet that hides the print button when the page is printed
    printWindow.document.write(`
            <style>
            @media print {
                .print-button {
                display: none;
                }
            }
            </style>
        `);

    // Add an onclick event to the print button that triggers the print dialog
    printWindow.document.querySelector(".print-button").onclick = () => {
      printWindow.print();
    };
  };

  return (
    <TableContainer component={Paper}>
      <Button
        label="Export PDF"
        className="bg-white border-black border p-3"
        onClick={tableToPDF}
      />
      <div className="flex items-center gap-10 pl-12 py-6">
        <p>Sort By:</p>
        <select
          className="outline-none"
          onChange={(e) => {
            setFilter(e.target.value);
            if (e.target.value === "") {
              setReportText("All Time.");
              setBookings(context?.allBookings);
            }
          }}
        >
          <option value="">All</option>
          <option value="year">Year</option>
          <option value="month">Month</option>
          <option value="day">Day</option>
          <option value="room">Room</option>
          <option value="email">Email</option>
        </select>
        <select
          className={`${
            filter === "month" ? "visible" : "hidden"
          } outline-none`}
          onChange={(e) => {
            setReportText(e.target.value);
            setBookings(
              context?.allBookings?.filter(
                (b) =>
                  `${b.data.dateIn.split("-")[0]} - ${
                    b.data.dateIn.split("-")[1]
                  }` === e.target.value
              )
            );
          }}
        >
          {uniqueMonths.map((m) => (
            <option value={m}>{m}</option>
          ))}
        </select>
        <select
          className={`${filter === "day" ? "visible" : "hidden"} outline-none`}
          onChange={(e) => {
            setReportText(e.target.value);
            setBookings(
              context?.allBookings?.filter(
                (b) =>
                  `${b.data.dateIn.split("-")[0]} - ${
                    b.data.dateIn.split("-")[1]
                  } - ${b.data.dateIn.split("-")[2]}` === e.target.value
              )
            );
          }}
        >
          {uniqueDays.map((m) => (
            <option value={m}>{m}</option>
          ))}
        </select>
        <select
          className={`${filter === "year" ? "visible" : "hidden"} outline-none`}
          onChange={(e) => {
            setReportText(e.target.value);
            setBookings(
              context?.allBookings?.filter(
                (b) => b.data.dateIn.split("-")[0] === e.target.value
              )
            );
          }}
        >
          {uniqueYears.map((y) => (
            <option value={y}>{y}</option>
          ))}
        </select>
        <select
          className={`${filter === "room" ? "visible" : "hidden"} outline-none`}
          onChange={(e) => {
            setReportText(e.target.value);
            setBookings(
              context?.allBookings?.filter(
                (b) =>
                  context?.rooms?.find((r) => r.data.roomId === b?.data?.roomId)
                    .data?.roomnumber === e.target.value
              )
            );
          }}
        >
          {rooms.map((r) => (
            <option value={r}>{r}</option>
          ))}
        </select>
        <select
          className={`${
            filter === "email" ? "visible" : "hidden"
          } outline-none`}
          onChange={(e) => {
            setReportText(e.target.value);
            setBookings(
              context?.allBookings?.filter(
                (b) => b.data.email === e.target.value
              )
            );
          }}
        >
          {uniqueMails.map((e) => (
            <option value={e}>{e}</option>
          ))}
        </select>
      </div>
      <Table aria-label="simple table" id="bookings-table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              <b>Name</b>
            </TableCell>
            <TableCell>
              <b>Email</b>
            </TableCell>
            <TableCell>
              <b>Room No.</b>
            </TableCell>
            <TableCell>
              <b>Room Price</b>
            </TableCell>
            <TableCell>
              <b>Date In</b>
            </TableCell>
            <TableCell>
              <b>Date Out</b>
            </TableCell>
            <TableCell>
              <b>Discounted Price</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bookings?.map((booking, index) => (
            <TableRow
              key={index}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:nth-of-type(odd)": {
                  backgroundColor: "#F0F1F2",
                },
                ":hover": {
                  opacity: 0.8,
                },
              }}
              onClick={() =>
                printReceipt(
                  booking?.data?.firstname + " " + booking?.data?.lastname,
                  booking.data.email,
                  context?.rooms?.find(
                    (r) => r.data.roomId === booking?.data?.roomId
                  ).data?.roomnumber,
                  getRoomPrice(booking?.data?.roomId) * booking?.data?.numDays,
                  booking?.data?.hasOwnProperty("discountedPrice")
                    ? booking?.data?.discountedPrice === ""
                      ? "-"
                      : booking?.data?.discountedPrice * booking?.data?.numDays
                    : "-",
                  booking?.data?.dateIn,
                  booking?.data?.dateOut,
                  booking?.data?.numDays
                )
              }
            >
              <TableCell></TableCell>
              <TableCell component="th" scope="row">
                {booking?.data?.firstname + " " + booking?.data?.lastname}
              </TableCell>
              <TableCell>{booking?.data?.email}</TableCell>
              <TableCell component="th" scope="row">
                {
                  context?.rooms?.find(
                    (r) => r.data.roomId === booking?.data?.roomId
                  ).data?.roomnumber
                }
              </TableCell>
              <TableCell>
                {getRoomPrice(booking?.data?.roomId) * booking?.data?.numDays}
              </TableCell>
              <TableCell>{booking?.data?.dateIn}</TableCell>
              <TableCell>{booking?.data?.dateOut}</TableCell>
              <TableCell>
                {booking?.data?.hasOwnProperty("discountedPrice")
                  ? booking?.data?.discountedPrice === ""
                    ? "-"
                    : booking?.data?.discountedPrice * booking?.data?.numDays
                  : "-"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default BookingsTable;
