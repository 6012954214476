import { Dialog, DialogTitle } from "@mui/material";
import { useState } from "react";
import Button from "../../components/basics/button";
import OrderTable from "../../components/widgets/order-table";
import { useAppContext } from "../../helpers/store";

function Orders() {
  const context = useAppContext();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState({
    name: "",
    id: "",
    roomnumber: "",
  });

  const ordersRegex = /super|manager|restaurant|bar/;

  return (
    <div className="w-full">
      <OrderTable
        orders={ordersRegex.test(context?.role) && context?.orders}
        setOrder={setOrder}
        setOpenConfirmation={setOpenConfirmation}
      />
      <Dialog
        onClose={() => setOpenConfirmation(false)}
        open={openConfirmation}
      >
        <DialogTitle>Confirm Delivery</DialogTitle>
        <div className="w-full border-y border-dark flex justify-center py-2 px-6">
          <p>
            Are you sure the order for {order.name} has been
            {`${
              order.roomnumber === "Admin"
                ? " paid for"
                : ` delivered to ${order.roomnumber}`
            }`}
            ?
          </p>
        </div>
        <div className="flex w-full items-center justify-end px-6 py-3">
          <Button
            label="Cancel"
            className="bg-white text-dark"
            onClick={() => setOpenConfirmation(false)}
          />
          <Button
            label="Yes, it's delivered."
            className="bg-dark text-white"
            loading={loading}
            onClick={async () => {
              setLoading(true);
              await fetch("https://api.ibomwaterfallsuites.com/confirm-order", {
                method: "POST",
                body: JSON.stringify({ id: order.id }),
                headers: {
                  "Content-Type": "application/json",
                },
              })
                .then(() => {
                  setLoading(false);
                  setOpenConfirmation(false);
                  context?.setrefresh(context?.refresh + 1);
                })
                .catch((e) => {
                  alert(e.message);
                  setLoading(false);
                  setOpenConfirmation(false);
                });
            }}
          />
        </div>
      </Dialog>
    </div>
  );
}

export default Orders;
