import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid"

function Incrementer({leftClick, rightClick, value}) {
    return (
        <div className="justify-between flex items-center">
            <ChevronLeftIcon className="w-5 h-5" onClick={leftClick} />
            <p>{value}</p>
            <ChevronRightIcon className="w-5 h-5" onClick={rightClick} />
        </div>
    )
}

export default Incrementer