import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import jsPDF from 'jspdf';
import Button from '../basics/button';
import { useAppContext } from '../../helpers/store';
import { useState } from 'react';

function OrderTable({orders, setOpenConfirmation, setOrder}) {
    const context = useAppContext()

    const [reportText, setReportText] = useState('All Time')

    const [filteredOrders, setFilteredOrders] = useState(orders)
    const [filter, setFilter] = useState('')


    const uniqueYears = [...new Set(orders?.map(b => b.data.createdAt.split("-")[0]))]
    const uniqueMonths = [...new Set(orders?.map(b => `${b.data.createdAt.split("-")[0]} - ${b.data.createdAt.split("-")[1]}`))]
    const uniqueDays = [...new Set(orders?.map(b => `${b.data.createdAt.split(" ")[0]}`))]

    const tableToPDF = () => {
        var doc = new jsPDF('l', 'pt', 'a4');
        doc.addImage("/images/PDF_header.png", 'PNG', 50, 10, 500, 80)
        doc.text(`Ibom Waterfall Suites Orders Report for ${reportText}`, 120, 120)
        doc.html(document.querySelector('#orders-table'), {
            callback: function (doc) {

            doc.save('orders.pdf');
            },
            margin: [10, 20, 50, 20],
            x: 10,
            y: 130,
            width: 800,
            windowWidth: 800
        });
        let pagesize = doc.internal.pageSize
        let pageheight = pagesize.height ? pagesize.height : pagesize.getHeight()
        console.log(pageheight)
        doc.addImage("/images/PDF_footer.png", 'PNG', 50, pageheight - 80, 500, 80)
    }

    const printReceipt = (bookedBy, items, total) => {
        // Create a printable version of the order
        const printableOrder = `
            <div>
            <h1>Order Receipt</h1>
            <p>Order placed by: ${bookedBy}</p>
            <ul>
                ${items.map(item => `
                <li>${item.name} - ${item.price}</li>
                `).join('')}
            </ul>
            <p>Total Amount: ${total}</p>
            <button class="print-button">Print Receipt</button>
            </div>
        `;
      
        // Create a new window with the printable version of the order
        const printWindow = window.open('', 'PrintWindow', 'height=500,width=500');
        printWindow.document.write(printableOrder);

        // Add a button to the printable version of the order that the user can click to trigger the print dialog
        // Wait for the window to finish loading printWindow.onload = () => {
        // Add a print stylesheet that hides the print button when the page is printed
        printWindow.document.write(`
            <style>
            @media print {
                .print-button {
                display: none;
                }
            }
            </style>
        `);

        // Add an onclick event to the print button that triggers the print dialog
        printWindow.document.querySelector('.print-button').onclick = () => {
            printWindow.print();
        };
    };

    return (
        <TableContainer component={Paper}>
            <Button 
                label="Export PDF"
                className="bg-white border-black border p-3"
                onClick={tableToPDF}
            />
            <div className='flex items-center gap-10 pl-12 py-6'>
                <p>Sort By:</p>
                <select 
                    className='outline-none' 
                    onChange={(e) => {
                        setFilter(e.target.value)
                        if(e.target.value === "") {
                            setReportText("All Time.")
                            setFilteredOrders(orders)
                        }
                    }}
                >
                    <option value="">All</option>
                    <option value="year">Year</option>
                    <option value="month">Month</option>
                    <option value="day">Day</option>
                </select>
                <select 
                    className={`${filter === 'month'? 'visible' : 'hidden'} outline-none`}
                    onChange={(e) => {
                        setReportText(e.target.value)
                        setFilteredOrders(orders?.filter(b => `${b.data.createdAt.split("-")[0]} - ${b.data.createdAt.split("-")[1]}` === e.target.value))
                    }}
                >
                    {uniqueMonths.map(m => (
                    <option value={m}>{m}</option>
                    ))}
                </select>
                <select 
                    className={`${filter === 'year'? 'visible' : 'hidden'} outline-none`}
                    onChange={(e) => {
                        setReportText(e.target.value)
                        setFilteredOrders(orders?.filter(b => b.data.createdAt.split("-")[0] === e.target.value))
                    }}
                >
                    {uniqueYears.map(y => (
                    <option value={y}>{y}</option>
                    ))}
                </select>
                <select 
                    className={`${filter === 'day'? 'visible' : 'hidden'} outline-none`}
                    onChange={(e) => {
                        setReportText(e.target.value)
                        setFilteredOrders(orders?.filter(b => b.data.createdAt.split(" ")[0] === e.target.value))
                    }}
                >
                    {uniqueDays.map(d => (
                    <option value={d}>{d}</option>
                    ))}
                </select>
            </div>
            <Table aria-label="simple table" id="orders-table" >
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell><b>Name</b></TableCell>
                        <TableCell><b>Room</b></TableCell>
                        <TableCell><b>Items</b></TableCell>
                        <TableCell><b>Price</b></TableCell>
                        <TableCell><b>Status</b></TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {filteredOrders?.map((order, index) => {
                    let booking = context?.allBookings.find(b => b.id === order?.data?.bookingId)
                    return(
                        <TableRow
                            key={index}
                            sx={{ 
                                '&:last-child td, &:last-child th': { border: 0 }, 
                                '&:nth-of-type(odd)': {
                                    backgroundColor: '#F0F1F2',
                                },
                                ":hover": {
                                    opacity: 0.8
                                }
                            }}
                            onClick={() => order?.data?.status === "Delivered" && printReceipt(order?.data?.bookingId, order?.data?.items, order?.data?.items?.reduce((sum, item) => sum + item.price, 0))}
                        >
                            <TableCell></TableCell>
                            <TableCell>{order?.data?.bookingId === "admin" ? "Admin" : booking?.data?.firstname+" "+booking?.data?.lastname}</TableCell>
                            <TableCell>{order?.data?.bookingId === "admin" ? "Admin" : context?.rooms?.find((r) => r.data.roomId === booking?.data?.roomId).data?.roomnumber }</TableCell>
                            <TableCell component="th" scope="row">
                                {order?.data?.items?.map((item) => item.name).join(" + ")}
                            </TableCell>
                            <TableCell>{order?.data?.items?.reduce((sum, item) => sum + item.price, 0)}</TableCell>
                            <TableCell>{order?.data?.status}</TableCell>
                            <TableCell align="right">
                                <Button 
                                    label={order?.data?.status === "Delivered" ? "Completed" : "Complete Order"}
                                    className="bg-dark text-white"
                                    onClick={() => {
                                        setOpenConfirmation(true)
                                        setOrder({name: order?.data?.items?.map((item) => item.name).join(" + "), id: order?.id, roomnumber: order?.data?.bookingId === 'admin' ? 'Admin' : context?.rooms?.find((r) => r.data.roomId === booking?.data?.roomId).data?.roomnumber})
                                    }}
                                    disabled={order?.data?.status === "Delivered"}
                                />
                            </TableCell>
                        </TableRow>
                    )
                })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default OrderTable