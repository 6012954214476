import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '../../components/basics/button';

function ReportsTable({reports, setOpenReport, setReport}) {
  return (
    <TableContainer>
        <Table aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell></TableCell>
                <TableCell><b>Name</b></TableCell>
                <TableCell><b>Email</b></TableCell>
                <TableCell><b>Time</b></TableCell>
                <TableCell><b>Room</b></TableCell>
                <TableCell align="right"></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {reports?.map((report, index) => (
                <TableRow
                key={index}
                sx={{ 
                    '&:last-child td, &:last-child th': { border: 0 }, 
                    '&:nth-of-type(odd)': {
                        backgroundColor: '#F0F1F2',
                    },
                    ":hover": {
                        opacity: 0.8
                    }
                }}
                >
                <TableCell></TableCell>
                <TableCell component="th" scope="row">
                    {report.displayName}
                </TableCell>
                <TableCell>{report.email}</TableCell>
                <TableCell>{report.time}</TableCell>
                <TableCell>{report.roomId}</TableCell>
                <TableCell align="right">
                    <Button 
                        label="View Report"
                        className="bg-white text-dark w-44 border border-[#E1E4E8]"
                        onClick={() => {
                            setReport({
                                displayName: report?.displayName?.split(" ")[0],
                                message: report?.message,
                            })
                            setOpenReport(true)
                        }}
                    />
                </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
    </TableContainer>
  )
}

export default ReportsTable