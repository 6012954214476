function LabeledInput({
  className,
  label,
  onChange,
  optional,
  placeholder,
  value,
  type,
}) {
  return (
    <div className="w-full mb-4">
      <span className="mb-1 flex items-center">
        <p className="mb-1 flex items-center font-medium gap-1 text-xs">
          {label}
        </p>
        {optional && (
          <p className="opacity-50 text-xs font-light">(Optional)</p>
        )}
      </span>
      <div className={className}>
        <input
          value={value}
          onChange={onChange}
          type={type ? type : "text"}
          placeholder={placeholder}
          className="w-full h-12 bg-transparent outline-none"
        />
      </div>
    </div>
  );
}

export default LabeledInput;
