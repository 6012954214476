import { XMarkIcon } from '@heroicons/react/24/solid'
import React from 'react'
import { useAppContext } from '../../helpers/store'

function SideView({children}) {
    const context = useAppContext()
    return (
        <div 
            className={`${context?.openSideView ? 'lg:w-2/5 w-3/4 px-8' : 'w-0'} transition-[width] duration-500 ease-in-out absolute bg-white top-0 right-0 py-20`}
            style={{
                // height
            }}
        >
            <div className={`${context?.openSideView ? 'block' : 'hidden'} w-full flex flex-col items-center`}>
                <div className='w-full'>
                    <XMarkIcon className='w-8 h-8 text-dark cursor-pointer' onClick={() => context?.setOpenSideView(false)} />
                </div>
                {children}
            </div>
        </div>
    )
}

export default SideView