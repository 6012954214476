import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCALE8Wq4oCDxLkbRX4JV-QyTuR90ofQZs",
    authDomain: "ibw-hotel.firebaseapp.com",
    projectId: "ibw-hotel",
    storageBucket: "ibw-hotel.appspot.com",
    messagingSenderId: "637951241239",
    appId: "1:637951241239:web:5f2ce842f924c1550ec052",
    measurementId: "G-MHSM3Z0XWG"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export methods
export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

export const logout = () => {
    signOut(auth)
}
