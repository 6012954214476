import { useAppContext } from "./helpers/store";
import PortalLayout from "./layouts/portal-layout";
import Administrators from "./layouts/portal/administrators";
import AllBookings from "./layouts/portal/all-bookings";
import BarMenu from "./layouts/portal/bar-menu";
import ManageContent from "./layouts/portal/manage-content";
import ManageRooms from "./layouts/portal/manage-rooms";
import Menu from "./layouts/portal/menu";
import Orders from "./layouts/portal/orders";
import Report from "./layouts/portal/report";

function Portal({ headerText, displayName, uid }) {
  const context = useAppContext();

  const getPortalView = () => {
    switch (context?.mode) {
      case "orders":
        // setPortalInfo({...portalInfo, headerText: `Here are your orders, ${user?.displayName}`})
        return <Orders />;
      case "menu":
        return <Menu />;
      case "bar-menu":
        return <BarMenu />;
      case "manage-rooms":
        return <ManageRooms />;
      case "reports":
        return <Report reports={context?.reports?.map((m) => m.data)} />;
      case "administrators":
        return <Administrators />;
      case "content":
        return <ManageContent />;
      case "bookings":
        return <AllBookings />;
      default:
        return <div />;
    }
  };

  return (
    <div>
      <PortalLayout
        headerText={headerText}
        displayName={displayName}
        cart={context?.cart}
        setcart={context?.setcart}
        uid={uid}
      >
        <div className="w-full bg-white rounded-md">{getPortalView()}</div>
      </PortalLayout>
    </div>
  );
}

export default Portal;
