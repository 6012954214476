import { PlusIcon } from '@heroicons/react/24/solid'
import React from 'react'
import AdminTable from '../../components/widgets/admin-table'
import { useAppContext } from '../../helpers/store'

function Administrators() {
    const context = useAppContext()
    return (
        <div className="w-full p-6">
                <div className="flex items-center w-full justify-between">
                    <div 
                        className="cursor-pointer lg:w-1/4 w-2/5 py-3 pl-2 bg-[#1B1F23] text-white rounded-md flex gap-2 items-center mb-5"
                        onClick={() => {
                            context?.setSideMode("add-admin")
                            context?.setOpenSideView(true)
                        }}
                    >
                        <PlusIcon className="w-6 h-6 text-white" />
                        <p className="text-white">Add an Administrator</p>
                    </div>
                </div>
                <AdminTable 
                    admins={context?.admins}
                    editAdmin={(uid, displayName) => {
                        context?.setSideMode("edit-admin")
                        context?.setOpenSideView(true)
                        context?.setAdminable({
                            firstname: displayName.split(" ")[0], 
                            lastname: displayName.split(" ")[1], 
                            uid
                        })
                    }}
                />
            </div>
    )
}

export default Administrators