import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import React, { useEffect, useState } from "react";
import Button from "../../components/basics/button";
import ContentSection from "../../components/widgets/content-section";
import { storage } from "../../helpers/firebase";
import { useAppContext } from "../../helpers/store";

function ManageContent() {
  const context = useAppContext();
  const [content, setContent] = useState([]);
  const [viewMode, setViewMode] = useState("");
  const [file, setFile] = useState(null);
  const [replace, setReplace] = useState(false);
  const [uploadLoader, setUploadLoader] = useState(false);

  useEffect(() => {
    setContent(context?.content);
  }, []);

  const upload = async () => {
    setUploadLoader(true);
    let imageRef = ref(storage, `images`);

    let editRef = ref(imageRef, `${file.name}`);
    await uploadBytes(editRef, file)
      .then((snapshot) => {
        console.log(snapshot);
      })
      .then(async () => {
        console.log(file?.name);
        await getDownloadURL(editRef).then(async (downloadUrl) => {
          const updatedSection = content.find((data) => data.id === viewMode);
          updatedSection.data[context?.editImages[0]?.section][
            context?.editImages[0]?.index
          ] = downloadUrl;

          const updatedContent = content.map((data) => {
            if (data.id === viewMode) {
              return updatedSection;
            } else {
              return data;
            }
          });

          await fetch("https://api.ibomwaterfallsuites.com/update-content", {
            method: "POST",
            body: JSON.stringify({
              page: viewMode,
              section: context?.editImages[0]?.section,
              urls: [...updatedSection.data[context?.editImages[0]?.section]],
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }).then(() => setUploadLoader(false));

          setContent(updatedContent);
          setFile(null);
          setReplace(false);
          context?.setEditImages([]);
        });
      });
  };

  const toggleViewMode = () => {
    switch (viewMode) {
      case "gallery":
        return (
          <div>
            <ContentSection
              upload={upload}
              pageContent={content?.find((page) => page.id === viewMode)}
            />
          </div>
        );
      case "homepage":
        return (
          <div>
            <ContentSection
              upload={upload}
              pageContent={content?.find((page) => page.id === viewMode)}
            />
          </div>
        );
      case "layout":
        return (
          <div>
            <ContentSection
              upload={upload}
              pageContent={content?.find((page) => page.id === viewMode)}
            />
          </div>
        );
      case "restaurant":
        console.log(content);
        return (
          <div>
            <ContentSection
              upload={upload}
              pageContent={content?.find((page) => page.id === viewMode)}
            />
          </div>
        );
      default:
        return <div />;
    }
  };
  return (
    <div className="w-full p-6">
      {context?.editImages?.length !== 0 ? (
        replace ? (
          <Button
            className="w-60 h-16 bg-dark text-white"
            label="Replace"
            onClick={upload}
            loading={uploadLoader}
          />
        ) : (
          <>
            <Button
              className="w-60 h-16 bg-dark text-white"
              label="Select Replacement"
              onClick={() => {
                let input = document.getElementById("image-input");
                input.click();
              }}
            />
            <input
              id="image-input"
              type="file"
              className="hidden"
              onChange={(e) => {
                setFile(e.target.files[0]);
                setReplace(true);
              }}
            />
          </>
        )
      ) : (
        <div className="w-full flex lg:flex-row flex-col lg:items-center justify-between gap-8 pb-16">
          {content?.map((item, index) => (
            <div
              key={index}
              className="flex lg:p-4 p-3 lg:w-1/6 bg-black rounded-md items-center justify-center cursor-pointer"
              onClick={() => setViewMode(item.id)}
            >
              <p className="uppercase text-white text-sm font-bold">
                {item.id}
              </p>
            </div>
          ))}
        </div>
      )}
      {toggleViewMode()}
    </div>
  );
}

export default ManageContent;
