import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useAppContext } from '../../helpers/store';
import Incrementer from '../basics/incrementer';

function CartTable({cart, setcart}) {
    const context = useAppContext()
    return (
        <TableContainer>
            <Table aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell><b>Name</b></TableCell>
                    <TableCell><b>Price</b></TableCell>
                    <TableCell><b>Quantity</b></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {context?.cart?.map((item, index) => (
                    <TableRow
                    key={index}
                    sx={{ 
                        '&:last-child td, &:last-child th': { border: 0 },
                        ":hover": {
                            opacity: 0.8
                        }
                    }}
                    >
                    <TableCell></TableCell>
                    <TableCell component="th" scope="row">
                        {item?.name}
                    </TableCell>
                    <TableCell>{item?.price}</TableCell>
                    <TableCell>
                        <Incrementer 
                            value={item?.quantity}
                            leftClick={() => {
                                let newCart = [...context?.cart]
                                if(newCart[index]?.quantity > 1) newCart[index].quantity = newCart[index]?.quantity - 1
                                context?.setcart(newCart)
                            }}
                            rightClick={() => {
                                let newCart = [...context?.cart]
                                newCart[index].quantity = newCart[index]?.quantity + 1
                                context?.setcart(newCart)
                            }}
                        />
                    </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default CartTable