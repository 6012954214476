import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '../../components/basics/button';

function RoomTable({activeBookings, endStay, manageRoom, rooms, toggleblock}) {
    const getRoomInfo = (roomId) => {
        let activeBooking = activeBookings?.find((ab) => ab?.data?.roomId === roomId)

        if(activeBooking !== undefined) {
            return {
                name: activeBooking.data.firstname + " " + activeBooking.data.lastname,
                email: activeBooking.data.email,
                dateOut: activeBooking.data.dateOut,
                status: 'Booked',
                tab: activeBooking.data.hasOwnProperty('tab') ? activeBooking.data.tab : []
            }
        } else {
            return {
                name: '-',
                email: '-',
                dateOut: '-',
                status: 'Available',
                tab: '-'
            }
        }
    }
    return (
        <TableContainer>
            <Table aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell><b>Name</b></TableCell>
                    <TableCell><b>Email</b></TableCell>
                    <TableCell><b>Room</b></TableCell>
                    <TableCell><b>Status</b></TableCell>
                    <TableCell><b>Date Out</b></TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {rooms?.map((item, index) => {
                    let roomInfo = getRoomInfo(item.data.roomId)
                    return (
                        <TableRow
                            key={index}
                            sx={{ 
                                '&:last-child td, &:last-child th': { border: 0 }, 
                                '&:nth-of-type(odd)': {
                                    backgroundColor: '#F0F1F2',
                                },
                                ":hover": {
                                    opacity: 0.8
                                }
                            }}
                        >
                            <TableCell></TableCell>
                            <TableCell component="th" scope="row">
                                {roomInfo.name}
                            </TableCell>
                            <TableCell>{roomInfo.email}</TableCell>
                            <TableCell>{item.data.roomnumber}</TableCell>
                            <TableCell>{roomInfo.status}</TableCell>
                            <TableCell>{roomInfo.dateOut}</TableCell>
                            <TableCell align="right">
                                {!item.data.blocked && (
                                <Button 
                                    label={roomInfo.status === 'Available' ? "Manage" : "End Stay"}
                                    className={`bg-white ${roomInfo.status === 'Available' ? "text-dark" : "text-red-500"} px-4 border border-[#E1E4E8]`}
                                    onClick={() => roomInfo.status === 'Available' ? manageRoom(item.id, item.data.roomId, item.data.price, item.data.roomtype, item.data.roomnumber) : endStay(item.id, item.data.roomId)}
                                />
                                )}
                            </TableCell>
                            <TableCell align="right">
                                {roomInfo.status === 'Available' ? (
                                    <Button 
                                        label={item.data.blocked? "Unblock" : "Block"}
                                        className={`bg-white ${!item.data.blocked ? "text-dark" : "text-red-500"} px-4 border border-[#E1E4E8]`}
                                        onClick={() => toggleblock(item.id, !item.data.blocked)}
                                    />
                                ) : (roomInfo.tab !== '-') && (roomInfo.tab.length !== 0) && (
                                    <Button 
                                        label={"Clear Tab"}
                                        className={`bg-white ${"text-red-500"} px-4 border border-[#E1E4E8]`}
                                        onClick={() => toggleblock(item.id, !item.data.blocked)}
                                    />
                                )}
                            </TableCell>
                            {/* <TableCell align="right">
                                {roomInfo.tab !== '-' && (
                                    <Button 
                                        label={"Clear Tab"}
                                        className={`bg-white ${"text-red-500"} px-4 border border-[#E1E4E8]`}
                                        onClick={() => toggleblock(item.id, !item.data.blocked)}
                                    />
                                )}
                            </TableCell> */}
                        </TableRow>
                    )
                })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default RoomTable