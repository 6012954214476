import moment from 'moment'
import React, { useEffect, useState } from 'react'
import BookingsTable from '../../components/widgets/bookings-table'
import { useAppContext } from '../../helpers/store'


function AllBookings() {
  const context = useAppContext()
  const [bookings, setBookings] = useState([])
  useEffect(() => {
    setBookings(context?.allBookings)
  }, [])

  return (
    <div className="w-full p-6">
        <BookingsTable 
            bookings={bookings.sort((a,b) => moment(b.data.dateIn) - moment(a.data.dateIn))}
            setBookings={setBookings}
        />
    </div>
  )
}

export default AllBookings

